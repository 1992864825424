<template>
    <v-navigation-drawer v-model="drawer" app expand-on-hover :style="{background: appBackground}">
        <v-list nav dense>
             <v-list-item v-for="item in items" :key="item.title" link :to="item.to">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-capitalize">{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import * as appActions from '../../store/actions/app'

export default {
    data() {
        return {
            items: [
                { title: "Home", icon: "mdi-home", to: "/" },
                { title: "Ricerca", icon: "mdi-magnify", to: "/find" },
                // { title: "Playlist", icon: "mdi-playlist-music", to: "/playlist" },
                // { title: "Libreria", icon: "mdi-bookshelf", to: "/library" },
                // { title: "Preferiti", icon: "mdi-heart", to: "/favorites" }
            ],
        }
    },

    computed: {
        ...mapState({
            getDrawer: state => state.appModule.drawer,
            appBackground: state => state.appModule.background
        }),
        drawer: {
            get() {
                return this.getDrawer
            },
            set(value) {
                this.setDrawer(value)
            }
        }
    },

    methods: {
        ...mapMutations({
            setDrawer: appActions.APP_SETDRAWERSTATUS
        })
    }
}
</script>

<style>

</style>
